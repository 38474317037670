<template lang="pug">
  div.tablist-setting
    v-scrollable
      .desktop-nav
        v-card(transparent)
          v-card-head
            v-card-head-label
              profile-header
          v-card-content
            v-tab-item(
              v-for="item in tabs"
              vertical
              :key="item.id"
              :active="isActive(item)"
              :path="item.path")
              | {{ item.title | translate}}

    .mobile-nav
      ui-dropdown-nav.mb-4(
        :title="'ui.labels.role' | translate"
        :options="tabs")

</template>

<script>
import ProfileHeader from '../ProfileHeader'
import UiDropdownNav from '@/components/ui/DropdownNav'

export default {
  name: 'TabsList',

  components: {
    ProfileHeader,
    UiDropdownNav
  },

  data: () => ({
    tabs: [
      { id: 0, title: 'pages.profile.general', path: '/profile/general' },
      { id: 1, title: 'pages.profile.organization', path: '/profile/organization' },
      { id: 2, title: 'pages.profile.settings', path: '/profile/settings' },
      { id: 3, title: 'pages.profile.email_preference', path: '/profile/email-preference' }
    ]
  }),

  methods: {
    isActive (item) {
      return this.$route.path === item.path
    }
  }
}
</script>

<style lang="scss" scoped>
.tablist-setting {
  .title-2 {
    font-weight: 700 !important;
    font-size: 20px !important;
  }

  p {
    font-size: 14px;
  }
}
</style>
<style lang="scss">
.tablist-setting {
  .v-card .v-card__head {
    padding-top: 0 !important;
  }
}
</style>
