<template lang="pug">
  .profile--avatar
    v-image-select.mr-20(
      :size="100"
      v-model="userInfo.profilePictureUrl"
      @input="cropSuccess")
    .kt-profile__main-info
      h2.title-2.mb-1 {{ userInfo.firstName }} {{ userInfo.lastName }}
      p {{ userInfo.position ? userInfo.position.name : '' }}

</template>

<script>
import api from '@/api'
import helpers from '@/util/helpers'

import VImageSelect from '@/components/ui/v-image-select/VImageSelect'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileHeader',

  components: {
    VImageSelect
  },

  data: () => ({
    imageCrop: {
      show: false,
      imgDataUrl: ''
    }
  }),

  methods: {
    async cropSuccess (imgDataUrl) {
      if (imgDataUrl) {
        let file = helpers.dataURLtoFile(imgDataUrl, 'profile.png')
        this.userInfo.profilePictureUrl = imgDataUrl
        await api.updateProfilePicture(file)
      } else {
        await api.deleteEmployeePicture(this.userInfo.id)
      }
    }
  },

  computed: {
    ...mapGetters('app', [
      'userInfo'
    ])
  }
}
</script>

<style>
.profile--avatar {
  display: flex;
  flex-direction: column;
}

.mr-20 {
  margin-right: 20px;
}
</style>
