<template lang="pug">
  .v-image-select(
    :style="styles"
    :class="wrapperClasses")

    .v-image-select__area(
      :class="classes"
      @dragleave="onDragleave"
      @dragover="preventDefault"
      @dragenter="onDragenter"
      @drop="handleChange")

      img.v-image-select__selected-img(
        v-if="imageUrl"
        :src="imageUrl")
      .v-image-select__drop-here

    v-btn.v-image-select__remove-btn(
      v-if="value"
      v-tooltip="$t('infotext.remove_picture')"
      fab
      shadow
      danger
      :style="{ top: btnsOffset, right: btnsOffset, position: 'absolute' }"
      @click="removeImage")
      i.fa.fa-times

    v-btn.v-image-select__upload-btn(
      v-tooltip="$t('infotext.upload_profile_picture')"
      fab
      shadow
      :style="{ bottom: btnsOffset, right: btnsOffset, position: 'absolute' }"
      @click="openSelectFileDialog")
      i.fa.fa-camera

    input(
      type="file"
      ref="fileInput"
      v-show="false"
      @change="handleChange")

    v-image-select-crop-dialog(
      v-if="showCroppper"
      :url="sourceImgUrl"
      @oncrop="setResultImage"
      @oncancel="closeCropper")
</template>

<script>
import VImageSelectCropDialog from './VImageSelectCropDialog'

export default {
  name: 'VImageSelect',

  components: {
    VImageSelectCropDialog
  },

  props: {
    value: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 80
    },
    maxSize: {
      type: Number,
      default: 10240
    },
    square: {
      type: Boolean,
      default: false
    },
    standartImg: {
      type: String,
      default: '/img/no-profile-picture.svg'
    }
  },

  data: () => ({
    sourceImgUrl: '',
    dragOver: false,
    showCroppper: false
  }),

  methods: {
    preventDefault (e) {
      e.preventDefault()
      return false
    },

    onDragenter (e) {
      this.dragOver = true
      e.preventDefault()
      return false
    },

    onDragleave (e) {
      this.dragOver = false
      e.preventDefault()
      return false
    },

    openSelectFileDialog (e) {
      if (this.$refs.fileInput) {
        if (e) {
          e.preventDefault()
        }
        this.$refs.fileInput.click()
      }
    },

    handleChange (e) {
      this.dragOver = false
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      if (files.length) {
        this.openFile(files[0])
      }
    },

    openFile (file) {
      if (this.checkFile(file)) {
        this.setSourceImg(file)
      }
    },

    checkFile (file) {
      if (file.type.indexOf('image') === -1) {
        // TODO: add message about incorrect file type
        return false
      }
      if (file.size / 1024 > this.maxSize) {
        // TODO: add message about file size limit error
        return false
      }
      return true
    },

    setSourceImg (file) {
      let fr = new FileReader()
      fr.onload = (e) => {
        this.sourceImgUrl = fr.result
        this.showCroppper = true
      }
      fr.readAsDataURL(file)
    },

    setResultImage (url) {
      this.$emit('input', url)
      this.closeCropper()
    },

    removeImage () {
      this.$emit('input', '')
    },

    closeCropper () {
      this.showCroppper = false
      this.$refs.fileInput.value = ''
    }
  },

  computed: {
    wrapperClasses () {
      return {
        'v-image-select--selected': this.hasSelected && !this.showCroppper
      }
    },

    classes () {
      return {
        'v-image-select__area--drag-over': this.dragOver,
        'v-image-select__area--square': this.square
      }
    },

    btnsOffset () {
      if (this.square) {
        return `${-10}px`
      }
      return `${this.size / 40}px`
    },

    styles () {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`
      }
    },

    hasSelected () {
      return !!this.value
    },

    imageUrl () {
      return this.value || this.standartImg
    }
  }
}
</script>

<style lang="scss">

  .v-image-select {
    position: relative;
    height: 80px;
    width: 80px;

    &__drop-here {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .5s;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 102%;
      width: 102%;
      background: url('../../../assets/icons/util/add.svg') no-repeat center;
      background-size: 45px 45px;
      background-color: $color-white;
      z-index: 5;
    }

    &__area {
      position: relative;
      cursor: pointer;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: $color-white;
      overflow: hidden;
      border: 2px solid $color-white;
      transition: .5s;
      backface-visibility: hidden;

      &--drag-over {
        transform: scale(1.2);
        box-shadow: 0px 0px 50px 0px rgba($color-black, 0.15);
        border: 2px dashed $border-color;
        z-index: 100;

        .v-image-select__drop-here {
          opacity: 1;
        }
      }

      &--drag-over ~ .v-image-select__upload-btn {
        opacity: 0;
      }

      &--square {
        border-radius: 0px;
      }
    }

    &__selected-img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__remove-btn,
    &__upload-btn {
      position: absolute;
      transition: .5s;
      z-index: 10;
    }

    &__remove-btn {
      opacity: 0;
    }

    &--selected:hover {
      .v-image-select__remove-btn {
        opacity: 1;
      }
    }
  }
</style>
