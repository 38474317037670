<template lang="pug">
  v-dialog(
    lockoverlay
    :width="348"
    :value="showDialog"
    @input="cancel")
    v-card
      v-card-content
        .v-cropper-img-container(
          @mousedown="dragMouseDown")
          img(
            :src="url"
            :style="sourceImgStyle")
          svg(width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg")
            path(fill-rule="evenodd" clip-rule="evenodd" d="M300 0H0V300H300V0ZM150 285C224.558 285 285 224.558 285 150C285 75.4415 224.558 15 150 15C75.4417 15 15 75.4415 15 150C15 224.558 75.4417 285 150 285Z" fill="white")

        canvas(
          v-show="false"
          ref="canvas")

        v-slider(
          :value="params.scale"
          @input="onZoomChange")

      v-card-actions
        v-btn(text @click="cancel") {{'ui.buttons.cancel' | translate}}
        v-btn(@click="createImage") {{'ui.buttons.save' | translate}}
</template>

<script>
const W = 300
const H = 300

export default {
  name: 'VImageSelectCropDialog',

  props: {
    url: String
  },

  mounted () {
    this.startCrop()
  },

  data: () => ({
    sourceImg: null,
    params: {
      initialWidth: 0,
      initialHeight: 0,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      scale: 1
    },
    cursor: {
      x: 0,
      y: 0
    },
    showDialog: false
  }),

  methods: {
    startCrop () {
      this.params.scale = 1
      this.params.x = 0
      this.params.y = 0

      let img = new Image()
      img.src = this.url
      img.onload = () => {
        let nWidth = img.naturalWidth
        let nHeight = img.naturalHeight
        let nRatio = nWidth / nHeight
        if (nRatio < 1) {
          // For portrait
          this.params.width = W
          this.params.height = W / nRatio
          this.params.initialWidth = this.params.width
          this.params.initialHeight = this.params.height
        } else {
          // For landscape image
          this.params.height = H
          this.params.width = H * nRatio
          this.params.initialWidth = this.params.width
          this.params.initialHeight = this.params.height
        }
        this.sourceImg = img
        this.centerImage()
        this.showDialog = true
      }
    },

    onZoomChange (value) {
      this.params.scale = value
      let coef = 1 + (value / 20)
      let prevHeight = this.params.height
      let prevWidth = this.params.width
      this.params.height = this.params.initialHeight * coef
      this.params.width = this.params.initialWidth * coef
      let dy = (this.params.height - prevHeight) / 2
      let dx = (this.params.width - prevWidth) / 2
      this.panImage(dx, dy, true)
    },

    centerImage () {
      let dy = (this.params.height - H) / 2
      let dx = (this.params.width - W) / 2
      this.params.x = -dx
      this.params.y = -dy
    },

    dragMouseDown (e) {
      e = e || window.event
      e.preventDefault()
      this.cursor.x = e.clientX
      this.cursor.y = e.clientY
      document.onmouseup = this.finishImagePan
      document.onmousemove = this.dragMouseMove
    },

    dragMouseMove (e) {
      e = e || window.event
      e.preventDefault()
      let shiftX = this.cursor.x - e.clientX
      let shiftY = this.cursor.y - e.clientY
      this.cursor.x = e.clientX
      this.cursor.y = e.clientY
      this.panImage(shiftX, shiftY)
    },

    panImage (shiftX, shiftY, isForZooming = false) {
      let newX = this.params.x - shiftX
      if (newX <= 0 && newX >= -(this.params.width - W)) {
        this.params.x = newX
      }
      let newY = this.params.y - shiftY
      if (newY <= 0 && newY >= -(this.params.height - H)) {
        this.params.y = newY
      }
      if (isForZooming) {
        if (newX < -(this.params.width - W)) {
          this.params.x += Math.abs(shiftX * 2)
        }
        if (newY < -(this.params.height - H)) {
          this.params.y += Math.abs(shiftY * 2)
        }
      }
    },

    finishImagePan () {
      document.onmouseup = null
      document.onmousemove = null
    },

    createImage () {
      let { x, y, height, width } = this.params
      let canvas = this.$refs.canvas
      let ctx = canvas.getContext('2d')
      canvas.width = W
      canvas.height = H
      ctx.clearRect(0, 0, W, H)
      ctx.clearRect(0, 0, W, H)
      ctx.fillRect(0, 0, W, H)
      ctx.drawImage(this.sourceImg, x, y, width, height)
      let resultImg = canvas.toDataURL()
      this.$emit('oncrop', resultImg)
    },

    cancel (value) {
      this.showDialog = value
      this.$emit('oncancel')
    }
  },

  computed: {
    sourceImgStyle () {
      return {
        top: this.params.y + 'px',
        left: this.params.x + 'px',
        width: this.params.width + 'px',
        height: this.params.height + 'px'
      }
    }
  }
}
</script>

<style lang="scss">
  .v-cropper-img-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px;
    border-radius: 4px;

    img {
      position: absolute;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .5;
    }
  }
</style>
